<template>
    <div class='statement'>
        <a-tabs @change="change" v-model:activeKey="tabIndex">
            <a-tab-pane 
                :key="tabIndex" 
                :tab="tab.label" 
                v-for="(tab, tabIndex) in tabs">
                <p class="refresh_time">
                    最近刷新时间： {{time}}
                    <a-button 
                        type="primary" 
                        :loading="loading" 
                        @click="loadData" 
                        class="refresh_time_button">{{loading ? '刷新中' : '立即刷新'}}</a-button>
                </p>
                 <ul class="statement_box">
                    <li 
                        v-for="(item, itemIndex) in statementInfo"
                        class="statement_box_info" 
                        :key="itemIndex">
                            <p class="blue info_value">{{appendCent(item.value)}}<span>{{item.unit}}</span></p>
                            <p class="info_title">{{item.title}}</p>
                            <!-- <div class="info_tip">
                                <p >
                                    <span>{{item.title}}</span>
                                    <span class="red">{{item.value}}</span>
                                    <span>{{tip.unit}}</span>
                                </p>
                            </div> -->
                        </li>
                    </ul>
                </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
import {computed, reactive, toRefs } from 'vue'
import { isValid, withdrawBalance } from '@/utils/api'
import { formatTime,  appendCent} from '@/utils/util'
export default {
    name: '',
    setup() {
        const state = reactive({
            tabs: [
                    {label: '银联账户余额', value: 1}, 
                    // {label: '彩民统计', value: 2},
                    // {label: '销量统计', value: 3},
                    // {label: '提现统计', value: 4},
                    // {label: '兑奖统计', value: 5},
                    // {label: '佣金统计', value: 6},
                ],
            tabIndex: 0,
            refreshTime: '',
            time: '',
            list: [],
            loading: false,
            statementInfo: [
                {
                    title: '银联账户余额（元）',
                    value: 0,
                    unit: '元'
                }
            ]
        })
        const showInfo = computed(() => {
            return state.statementInfo[state.tabs[state.tabIndex].value]
        })
        async function loadBalance () {
            try {
                const result = await withdrawBalance()
                if (isValid(result)) {
                    state.statementInfo[0].value = result.data.unionPay
                }
            } catch(e) {
                console.error(e)
            }
        }
        async function loadData() {
            try {
                // const tab = state.tabs[state.tabIndex]
                if (state.tabIndex == 0) {
                    await loadBalance()
                }
                state.time = formatTime(new Date())
                state.loading = false
            } catch(e) {
                console.error(e)
            }
        }
        function change(e) {
            state.tabIndex = e 
            loadData()
        }
        loadData()
        return {
            ...toRefs(state),
            showInfo,
            change,
            loadData,
            appendCent
        }
    }
}
</script>
<style lang="scss">
.ant-tabs-nav-scroll {
    text-align: left;
}
.black {
    color: #000;
}
.blue {
    color: #276DFF;
}
.gray {
    color: #626262;
}
.red {
    color: #f00;
}
.refresh_time {
    color: #666;
    text-align: left;
    padding: 20px 0 8px 0;
    &_button {
        display: inline-block;
        color: #fff;
        background: #276DFF;
        padding: 5px 15px;
        margin-left: 20px;
    }
}
.statement_box {
    display: flex;
    margin-right: 20px;
    &_info {
        background: #F7F7F7;
        border: solid #F7F7F7 1px;
        margin-right: 20px;
        padding: 20px;
        width: 180px;
        border-radius: 5px;
        &:hover {
            border: solid 1px  #276DFF;
            background:#F3F6FF;
        }
        p {
            margin: 0;
        }
        .info {
            &_title {
                font-size: 14px;
                color: #000;
            }
            &_value {
                font-size: 32px;
                padding: 10px 0;
                span {
                    font-size: 14px;
                }
            }
            
            &_tip {
                display: flex;
                text-align: center;
                flex-wrap: wrap;
                justify-content: center;
                font-size: 12px;
                p {
                    margin: 0 5px;
                }
            }
        }
    }
}
</style>
